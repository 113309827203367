import React from 'react';
import './Navigation.css';

const Navigation = () => {
  return (
    <header className="header">
      <div className="header-container">
        <nav className="header-nav">
          <a href="https://www.seedlingnaturopathy.com.au" className="back-to-main">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M19 12H5M12 19l-7-7 7-7"/>
            </svg>
            Back to main site
          </a>
        </nav>
        
        <div className="header-title">
          <a href="https://www.seedlingnaturopathy.com.au" id="site-title">
            Seedling Naturopathy
          </a>
        </div>
      </div>
    </header>
  );
};

export default Navigation;