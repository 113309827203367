import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Success = ({ appState, updateAppState }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');

    if (sessionId) {
      // Payment was successful
      // You might want to verify the session with your backend here
      console.log('Payment successful. Session ID:', sessionId);
      // Clear the cart
      updateAppState({ cart: [], step: 1 });
    } else {
      // User might have navigated back or refreshed the page
      console.log('No session ID found. User might have navigated back.');
      // You might want to check if there's still items in the cart
      if (appState.cart.length === 0) {
        // If cart is empty, redirect to home
        navigate('/');
      }
    }
  }, [location, navigate, updateAppState, appState.cart]);

  return (
    <div className="success-page">
      <h1>Thank You for Your Order!</h1>
      {appState.cart.length > 0 ? (
        <div>
          <p>Your order is being processed.</p>
          {/* Display order summary */}
        </div>
      ) : (
        <p>Your order has been successfully placed.</p>
      )}
      <button onClick={() => navigate('/')}>Return to Home</button>
    </div>
  );
};

export default Success;