import React from 'react';
import { Button } from './ui/Button';
import '../animations.css';

const QuantityControlSansRemove = ({ quantity, onIncrease, onDecrease, onRemove }) => {
  return (
    <div className="quantity-control-container flex items-center space-x-2 fade-in">
      <Button
        onClick={onDecrease}
        className="quantity-button bg-gray-200 hover:bg-gray-300 text-secondary font-bold px-2 py-1 rounded-full pulse"
        disabled={quantity <= 1}
        aria-label="Decrease quantity"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </Button>
      
      <span className="quantity-display bg-white border border-gray-300 rounded-md px-3 py-1 text-center min-w-[40px] scale-in">
        {quantity}
      </span>
      
      <Button
        onClick={onIncrease}
        className="quantity-button bg-gray-200 hover:bg-gray-300 text-secondary font-bold px-2 py-1 rounded-full pulse"
        aria-label="Increase quantity"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </Button>
    </div>
  );
};

export default QuantityControlSansRemove;