import React, { useState, useEffect } from 'react';

const StickyIngredientList = ({ selectedIngredients }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      const accordionElement = document.querySelector('.accordion');
      if (accordionElement) {
        const rect = accordionElement.getBoundingClientRect();
        setIsVisible(rect.top <= 0);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  if (!isVisible || selectedIngredients.length === 0) return null;

  return (
    <div className={`sticky-ingredients ${isVisible ? 'visible' : ''}`}>
      <p>Selected ingredients: {selectedIngredients.map(i => i.name).join(', ')}</p>
    </div>
  );
};

export default StickyIngredientList;