import axios from 'axios';

const API_KEY = 'AIzaSyAAiB5ljDhobZqS6E3YHjcn46HXd4XVNoE'; // Replace with your API key
const SHEET_ID = '1Wq79ffklllCz6dAMTJ7NeeidoQ8KA7xjd8jZI35XOiA'; // Replace with your Google Sheet ID
const INGREDIENTS_RANGE = 'Sheet1'; // Adjust this if your sheet has a different name or range
const SIGNATURE_BLENDS_RANGE = 'Sheet2'; // Adjust this if your sheet has a different name or range

export const fetchSheetData = async () => {
  const ingredientsUrl = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${INGREDIENTS_RANGE}?key=${API_KEY}`;
  const signatureBlendsUrl = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SIGNATURE_BLENDS_RANGE}?key=${API_KEY}`;

  const ingredientsResponse = await axios.get(ingredientsUrl);
  const signatureBlendsResponse = await axios.get(signatureBlendsUrl);

  const ingredientsRows = ingredientsResponse.data.values;
  const signatureBlendsRows = signatureBlendsResponse.data.values;

  const ingredients = ingredientsRows.slice(1).map((row) => ({
    id: row[0],
    name: row[1],
    benefits: row[2],
    image: row[3],
    cost: parseFloat(row[4]),
    tags: [row[4], row[5], row[6]].filter(Boolean), // Updated to use columns E, F, G for tags
    pregnancyInfo: row[7], // Field for pregnancy information
    flavourProfile: row[8] // New field for flavour profile
  }));

  const signatureBlends = signatureBlendsRows.slice(1).map((row) => ({
    id: row[0],
    blend_name: row[1],
    blend_desc: row[3],
    ingredient_ids: row[2].split(',').map(id => id.trim()),
    icon_name: row[4] // This should contain the Material Symbol name (e.g., "bed", "oncology", "hematology", etc.)
  }));

  return { ingredients, signatureBlends };
};