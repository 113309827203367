import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#F9D8D6] text-[#30493C] py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="text-center">
            <h3 className="font-bold mb-4">CONTACT</h3>
            <p className="mb-2">anabelle@seedlingnaturopathy.com.au</p>
            <p>Fairfield Wellness<br></br>145C Station Street,<br></br>Fairfield, 3078, VIC<br></br></p>
          </div>
          <div className="text-center">
            <h3 className="font-bold mb-4">MENU</h3>
            <nav>
              <ul className="space-y-2">
                <li><Link to="https://www.seedlingnaturopathy.com.au/about-naturopathy">About Naturopathy</Link></li>
                <li><Link to="https://www.seedlingnaturopathy.com.au/privacy-policy">Privacy Policy</Link></li>
                <li><Link to="https://www.seedlingnaturopathy.com.au/contact">Contact</Link></li>
                <li><Link to="https://www.seedlingnaturopathy.com.au/book-appointment">Book Appointment</Link></li>
              </ul>
            </nav>
          </div>
          <div className="text-center">
            <h3 className="font-bold mb-4">FOLLOW ME</h3>
            <a href="https://www.instagram.com/seedlingnaturopathy/" target="_blank" rel="noopener noreferrer">Instagram</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;